<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">已完成的任务</h2>
        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="taskName" label="任务名称" align="center">
          </el-table-column>
          <el-table-column prop="assignees" label="分配人" align="center">
          </el-table-column>
          <el-table-column prop="executor" label="执行人" align="center">
          </el-table-column>
          <el-table-column prop="statusName" label="任务状态" align="center">
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-main>
    </el-container>


  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    return {
      reayName: "",
      // 学组管理的分页
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: [],
      taskId:""
    };
  },
  created() {
    this.getTaskList();
  },
  methods: {
    change(e) {
      this.$forceUpdate(e);
    },
    // 学组管理分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.getTaskList();
    },
    async getTaskList() {
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
        status:4,//状态 0待处理/1进行中/2已提交/3已完成
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
      });
      let { data: res } = await this.$axios.commissionList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-pagination{
  text-align: center;
}
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

.btnDiv{
  text-align: center;
  margin-top: 20px;
  button {
    width: 150px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}
.depart-addnum {
  width: 100%;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;
    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.text-ellipsis{
  width:380px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .el-textarea__inner {
  width: 220px !important;
}

/deep/ .el-input__inner {
  width: 220px !important;
}

</style>
